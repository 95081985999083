import api from "@/axios";

export default {
  findAll() {
    return api().get("race-recording");
  },
  findOne(id) {
    return api().get(`admin/race-recording/${id}`);
  },
  create(data) {
    return api().post("race-recording", data);
  },
  update(id, data) {
    return api().patch(`admin/race-recording/${id}`, data);
  },
  remove(id) {
    return api().delete(`race-recording/${id}`);
  },
};
