<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('competitions.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/competition'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('competitions.create')"
        createRouteName="events.competitionCreate"
        :excelFields="excelFields"
        excelName="competitions"
        :downloadSampleStatus="true"
        downloadSampleUrl="competition/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="competition/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  data() {
    return {
      filter: {
        search: "",
      },
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        { key: "image", label: this.$t("global.image"), type: "image" },
        {
          key: "country.name",
          label: this.$t("competitions.country"),
          sortable: true,
        },
        {
          key: "city.name",
          label: this.$t("competitions.city"),
          sortable: true,
        },
        {
          key: "start_date",
          label: this.$t("competitions.startDate"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "end_date",
          label: this.$t("competitions.endDate"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "events.competitionView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "events.competitionUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("competitions.item"),
              textContent: "name",
              url: "admin/competition",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.name")]: "name",
        [this.$t("competitions.country")]: "countryName",
        [this.$t("competitions.city")]: "cityName",
        [this.$t("competitions.startDate")]: "start_date",
        [this.$t("competitions.endDate")]: "end_date",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  mixins: [searchMixin],
  mounted() {
    core.index();
  },
  created() {
    this.filter.sponsor_id = this.sponsorId;
    this.filter.country_id = this.countryId;
    this.filter.city_id = this.cityId;
  },
};
</script>
