<template>
  <div>
    <b-card>
      <ValidationObserver ref="raceRecording" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="12">
            <!-- <input-file v-model="raceRecording.cover_image" :defaultUrl="raceRecording.cover_imageUrl" :name="$t('global.coverImage')" :label="$t('global.coverImage')" :validate="currentPage == itemPages.create ? '' : ''" :disabled="disableStatus" /> -->
            <cropper-images
                :label="$t('global.coverImage')"
                nameOfImage="image.jpg"
                @cropper-save="raceRecording.cover_image = $event.file ? $event.file : null"
                :multi="false"
                :imageUrl="raceRecording.cover_imageUrl"
                :ratio="1"
                :disabled="disableStatus"
            />
          </b-col>

          <b-col md="6">
            <input-form v-model="raceRecording.translations[0].title" :name="$t('raceRecordings.titleAr')" :label="$t('raceRecordings.titleAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="raceRecording.translations[1].title" :name="$t('raceRecordings.titleEn')" :label="$t('raceRecordings.titleEn')" validate="required" :disabled="disableStatus" />
          </b-col>

          <b-col md="12">
            <input-form v-model="raceRecording.url" :name="$t('global.url')" :label="$t('global.url')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <competitions :raceId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import raceRecordingServices from '../services/raceRecordings'
import RaceRecording from '../models/RaceRecording'
import Competitions from '../../competitions/views/Competitions.vue'
import { objectToFormData } from '../../../../Utils/helper'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Competitions },
  data () {
    return {
      raceRecording: new RaceRecording()
    }
  },
  props: { raceId: { type: String, default: '' } },
  methods: {
    async create () {
      this.raceRecording.raceId = this.raceId
      const formData = objectToFormData(this.raceRecording)
      raceRecordingServices.create(formData).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'events.raceView', params: { id: this.raceRecording.raceId } })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      const formData = objectToFormData(this.raceRecording)
      raceRecordingServices.update(this.id, formData).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'events.raceView', params: { id: this.raceRecording.raceId } })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      raceRecordingServices.findOne(this.id).then(response => {
        this.raceRecording.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
    if (this.currentPage === this.itemPages.create && !this.raceId) {
      this.$router.push({ name: 'events.races' })
    }
  }
}
</script>
