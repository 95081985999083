export default class RaceRecording {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.cover_image = ''
    this.cover_imageUrl = null
    this.url = ''
    this.race_id = null
    this.translations = [
      { id: null, title: '', lang: 'ar' },
      { id: null, title: '', lang: 'en' }
    ]
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.cover_imageUrl = data.cover_image ? data.cover_image : null
      this.url = data.url ? data.url : ''
      this.race_id = data.race_id ? data.race_id : null
      this.translations[0].id = itemAr ? itemAr.id : null
      this.translations[0].title = itemAr ? itemAr.title : ''
      this.translations[1].id = itemEn ? itemEn.id : null
      this.translations[1].title = itemEn ? itemEn.title : ''
    } else {
      this.setInitialValue()
    }
  }
}
